/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PhoneNumberLookupResultDTO } from '../models/PhoneNumberLookupResultDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PhoneNumberLookupService {

    /**
     * Perform a phone number lookup
     * @param country
     * @param phoneNumber
     * @returns PhoneNumberLookupResultDTO OK
     * @throws ApiError
     */
    public static phoneNumberLookup(
        country: string,
        phoneNumber: string,
    ): CancelablePromise<PhoneNumberLookupResultDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rest/phone-number-lookup/',
            query: {
                'country': country,
                'phoneNumber': phoneNumber,
            },
            errors: {
                500: `Internal server error`,
            },
        });
    }

}
