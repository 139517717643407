/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PasswordStrengthDTO } from '../models/PasswordStrengthDTO';
import type { PasswordStrengthEvaluationRequestDTO } from '../models/PasswordStrengthEvaluationRequestDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PasswordStrengthService {

    /**
     * Request a password strength evaluation
     * @param requestBody
     * @returns PasswordStrengthDTO OK
     * @throws ApiError
     */
    public static evaluatedPasswordStrength(
        requestBody: PasswordStrengthEvaluationRequestDTO,
    ): CancelablePromise<PasswordStrengthDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rest/password-strength/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                500: `Internal server error`,
            },
        });
    }

}
