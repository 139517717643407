/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmployeeRegistrationPasswordStrengthEvaluationRequestDTO } from '../models/EmployeeRegistrationPasswordStrengthEvaluationRequestDTO';
import type { EmployeeRegistrationStateDTO } from '../models/EmployeeRegistrationStateDTO';
import type { EnterPasswordForEmployeeRegistrationDTO } from '../models/EnterPasswordForEmployeeRegistrationDTO';
import type { EnterPhoneVerificationCodeForEmployeeRegistrationDTO } from '../models/EnterPhoneVerificationCodeForEmployeeRegistrationDTO';
import type { IdDTO } from '../models/IdDTO';
import type { PasswordStrengthDTO } from '../models/PasswordStrengthDTO';
import type { StartEmployeeRegistrationDTO } from '../models/StartEmployeeRegistrationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EmployeeRegistrationService {

    /**
     * Retry the phone verification procedure for an employee registration
     * @param employeeRegistrationId
     * @returns void
     * @throws ApiError
     */
    public static retryPhoneVerificationForEmployeeRegistration(
        employeeRegistrationId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/employee-registration/{employeeRegistrationId}/retry-phone-verification',
            path: {
                'employeeRegistrationId': employeeRegistrationId,
            },
            errors: {
                400: `Bad request`,
                404: `Employee registration not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Restart the phone verification procedure for an employee registration
     * @param employeeRegistrationId
     * @returns void
     * @throws ApiError
     */
    public static restartPhoneVerificationForEmployeeRegistration(
        employeeRegistrationId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/employee-registration/{employeeRegistrationId}/restart-phone-verification',
            path: {
                'employeeRegistrationId': employeeRegistrationId,
            },
            errors: {
                400: `Bad request`,
                404: `Employee registration not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Enter a phone verification code for an employee registration
     * @param employeeRegistrationId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static enterPhoneVerificationCodeForEmployeeRegistration(
        employeeRegistrationId: string,
        requestBody: EnterPhoneVerificationCodeForEmployeeRegistrationDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/employee-registration/{employeeRegistrationId}/phone-verification-code',
            path: {
                'employeeRegistrationId': employeeRegistrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Employee registration not found`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Enter new account password for an employee registration
     * @param employeeRegistrationId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static enterPasswordForEmployeeRegistration(
        employeeRegistrationId: string,
        requestBody: EnterPasswordForEmployeeRegistrationDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/employee-registration/{employeeRegistrationId}/password',
            path: {
                'employeeRegistrationId': employeeRegistrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Employee registration not found`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Evaluate the strength of a provided password for an employee registration
     * @param employeeRegistrationId
     * @param requestBody
     * @returns PasswordStrengthDTO Password strength evaluated
     * @throws ApiError
     */
    public static evaluatedPasswordStrengthForEmployeeRegistration(
        employeeRegistrationId: string,
        requestBody: EmployeeRegistrationPasswordStrengthEvaluationRequestDTO,
    ): CancelablePromise<PasswordStrengthDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rest/employee-registration/{employeeRegistrationId}/password-strength',
            path: {
                'employeeRegistrationId': employeeRegistrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Employee registration not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Start a new employee registration
     * @param requestBody
     * @returns IdDTO Employee registration started
     * @throws ApiError
     */
    public static startEmployeeRegistration(
        requestBody: StartEmployeeRegistrationDTO,
    ): CancelablePromise<IdDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rest/employee-registration/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                403: `No permission to grant group membership`,
                404: `Group not found`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Retrieve the state of an employee registration
     * @param employeeRegistrationId
     * @returns EmployeeRegistrationStateDTO Employee registration state retrieved
     * @throws ApiError
     */
    public static employeeRegistrationState(
        employeeRegistrationId: string,
    ): CancelablePromise<EmployeeRegistrationStateDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rest/employee-registration/{employeeRegistrationId}',
            path: {
                'employeeRegistrationId': employeeRegistrationId,
            },
            errors: {
                404: `Employee registration not found`,
                500: `Internal server error`,
            },
        });
    }

}
