/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EnterPasswordForFlexServiceWorkerRegistrationDTO } from '../models/EnterPasswordForFlexServiceWorkerRegistrationDTO';
import type { EnterPhoneNumberForFlexServiceWorkerRegistrationDTO } from '../models/EnterPhoneNumberForFlexServiceWorkerRegistrationDTO';
import type { EnterPhoneVerificationCodeForFlexServiceWorkerRegistrationDTO } from '../models/EnterPhoneVerificationCodeForFlexServiceWorkerRegistrationDTO';
import type { FlexServiceWorkerRegistrationPasswordStrengthEvaluationRequestDTO } from '../models/FlexServiceWorkerRegistrationPasswordStrengthEvaluationRequestDTO';
import type { FlexServiceWorkerRegistrationStateDTO } from '../models/FlexServiceWorkerRegistrationStateDTO';
import type { FlexServiceWorkerRegistrationStatusDTO } from '../models/FlexServiceWorkerRegistrationStatusDTO';
import type { PasswordStrengthDTO } from '../models/PasswordStrengthDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class FlexServiceWorkerRegistrationService {

    /**
     * Initiate personal name resolution for a FlexService worker registration, after a previous attempt to do so has failed
     * @param flexServiceWorkerRegistrationId
     * @returns void
     * @throws ApiError
     */
    public static resolvePersonalNameForFlexServiceWorkerRegistration(
        flexServiceWorkerRegistrationId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/flexservice-worker-registration/{flexServiceWorkerRegistrationId}/resolve-personal-name',
            path: {
                'flexServiceWorkerRegistrationId': flexServiceWorkerRegistrationId,
            },
            errors: {
                400: `Bad request`,
                404: `FlexService worker registration not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Request a phone verification stop, during a FlexService worker registration
     * @param flexServiceWorkerRegistrationId
     * @returns void
     * @throws ApiError
     */
    public static requestPhoneVerificationStopForFlexServiceWorkerRegistration(
        flexServiceWorkerRegistrationId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/flexservice-worker-registration/{flexServiceWorkerRegistrationId}/phone-verification-stop-request',
            path: {
                'flexServiceWorkerRegistrationId': flexServiceWorkerRegistrationId,
            },
            errors: {
                400: `Bad request`,
                404: `FlexService worker registration not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Enter a phone verification code during a FlexService worker registration
     * @param flexServiceWorkerRegistrationId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static enterPhoneVerificationCodeForFlexServiceWorkerRegistration(
        flexServiceWorkerRegistrationId: string,
        requestBody: EnterPhoneVerificationCodeForFlexServiceWorkerRegistrationDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/flexservice-worker-registration/{flexServiceWorkerRegistrationId}/phone-verification-code',
            path: {
                'flexServiceWorkerRegistrationId': flexServiceWorkerRegistrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `FlexService worker registration not found`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Enter the phone number to use as second factor, for a FlexService worker registration
     * @param flexServiceWorkerRegistrationId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static enterPhoneNumberForFlexServiceWorkerRegistration(
        flexServiceWorkerRegistrationId: string,
        requestBody: EnterPhoneNumberForFlexServiceWorkerRegistrationDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/flexservice-worker-registration/{flexServiceWorkerRegistrationId}/phone-number',
            path: {
                'flexServiceWorkerRegistrationId': flexServiceWorkerRegistrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `FlexService worker registration not found`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Enter the new account password during a FlexService worker registration
     * @param flexServiceWorkerRegistrationId
     * @param requestBody
     * @returns FlexServiceWorkerRegistrationStatusDTO New password entered
     * @throws ApiError
     */
    public static enterPasswordForFlexServiceWorkerRegistration(
        flexServiceWorkerRegistrationId: string,
        requestBody: EnterPasswordForFlexServiceWorkerRegistrationDTO,
    ): CancelablePromise<FlexServiceWorkerRegistrationStatusDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/flexservice-worker-registration/{flexServiceWorkerRegistrationId}/password',
            path: {
                'flexServiceWorkerRegistrationId': flexServiceWorkerRegistrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `FlexService worker registration not found`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Evaluate the strength of a provided password for a FlexService worker registration
     * @param flexServiceWorkerRegistrationId
     * @param requestBody
     * @returns PasswordStrengthDTO Password strength evaluated
     * @throws ApiError
     */
    public static evaluatedPasswordStrengthForFlexServiceWorkerRegistration(
        flexServiceWorkerRegistrationId: string,
        requestBody: FlexServiceWorkerRegistrationPasswordStrengthEvaluationRequestDTO,
    ): CancelablePromise<PasswordStrengthDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/flexservice-worker-registration/{flexServiceWorkerRegistrationId}/password-strength-evaluation',
            path: {
                'flexServiceWorkerRegistrationId': flexServiceWorkerRegistrationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `FlexService worker registration not found, or password criteria unknown`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Retrieve the state of a FlexService worker registration process
     * @param flexServiceWorkerRegistrationId
     * @returns FlexServiceWorkerRegistrationStateDTO Registration state retrieved
     * @throws ApiError
     */
    public static flexServiceWorkerRegistrationState(
        flexServiceWorkerRegistrationId: string,
    ): CancelablePromise<FlexServiceWorkerRegistrationStateDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rest/flexservice-worker-registration/{flexServiceWorkerRegistrationId}',
            path: {
                'flexServiceWorkerRegistrationId': flexServiceWorkerRegistrationId,
            },
            errors: {
                404: `Registration state not found`,
                500: `Internal server error`,
            },
        });
    }

}
