/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { IdDTO } from '../models/IdDTO';
import type { StartTwoFactorAuthenticationDTO } from '../models/StartTwoFactorAuthenticationDTO';
import type { TwoFactorAuthenticationChangePasswordDTO } from '../models/TwoFactorAuthenticationChangePasswordDTO';
import type { TwoFactorAuthenticationEnterPhoneVerificationCodeDTO } from '../models/TwoFactorAuthenticationEnterPhoneVerificationCodeDTO';
import type { TwoFactorAuthenticationStatusDTO } from '../models/TwoFactorAuthenticationStatusDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TwoFactorAuthenticationService {

    /**
     * Retry a failed phone verification
     * @param twoFactorAuthenticationId
     * @returns void
     * @throws ApiError
     */
    public static retryPhoneVerification(
        twoFactorAuthenticationId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rest/two-factor-authentication/{twoFactorAuthenticationId}/retry-phone-verification',
            path: {
                'twoFactorAuthenticationId': twoFactorAuthenticationId,
            },
            errors: {
                400: `Bad request`,
                404: `Authentication not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Restart an authentication phone verification
     * @param twoFactorAuthenticationId
     * @returns void
     * @throws ApiError
     */
    public static restartAuthenticationPhoneVerification(
        twoFactorAuthenticationId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rest/two-factor-authentication/{twoFactorAuthenticationId}/restart-phone-verification',
            path: {
                'twoFactorAuthenticationId': twoFactorAuthenticationId,
            },
            errors: {
                400: `Bad request`,
                404: `Authentication not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Enter a new password for an account while authenticating
     * @param twoFactorAuthenticationId
     * @param requestBody
     * @returns TwoFactorAuthenticationStatusDTO Password entered
     * @throws ApiError
     */
    public static changeAuthenticationPassword(
        twoFactorAuthenticationId: string,
        requestBody: TwoFactorAuthenticationChangePasswordDTO,
    ): CancelablePromise<TwoFactorAuthenticationStatusDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rest/two-factor-authentication/{twoFactorAuthenticationId}/password',
            path: {
                'twoFactorAuthenticationId': twoFactorAuthenticationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Authentication not found`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Enter a phone verification code
     * @param twoFactorAuthenticationId
     * @param requestBody
     * @returns TwoFactorAuthenticationStatusDTO Verification code entered
     * @throws ApiError
     */
    public static enterPhoneVerificationCode(
        twoFactorAuthenticationId: string,
        requestBody: TwoFactorAuthenticationEnterPhoneVerificationCodeDTO,
    ): CancelablePromise<TwoFactorAuthenticationStatusDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rest/two-factor-authentication/{twoFactorAuthenticationId}/enter-phone-verification-code',
            path: {
                'twoFactorAuthenticationId': twoFactorAuthenticationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Authentication not found`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Start an authentication procedure, providing an account name and a password
     * @param requestBody
     * @returns IdDTO Authentication procedure started
     * @throws ApiError
     */
    public static startAuthentication(
        requestBody: StartTwoFactorAuthenticationDTO,
    ): CancelablePromise<IdDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rest/two-factor-authentication/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

}
