/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RememberMeAuthenticationResultDTO } from '../models/RememberMeAuthenticationResultDTO';
import type { StartRememberMeAuthenticationDTO } from '../models/StartRememberMeAuthenticationDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RememberMeAuthenticationService {

    /**
     * Start an authentication procedure, providing a remember me token
     * @param requestBody
     * @returns RememberMeAuthenticationResultDTO Authentication attempted
     * @throws ApiError
     */
    public static startRememberMeAuthentication(
        requestBody: StartRememberMeAuthenticationDTO,
    ): CancelablePromise<RememberMeAuthenticationResultDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rest/remember-me-authentication/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

}
