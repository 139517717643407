/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountRecoveryPasswordStrengthEvaluationRequestDTO } from '../models/AccountRecoveryPasswordStrengthEvaluationRequestDTO';
import type { AccountRecoveryStateDTO } from '../models/AccountRecoveryStateDTO';
import type { EnterPasswordForAccountRecoveryDTO } from '../models/EnterPasswordForAccountRecoveryDTO';
import type { EnterPhoneVerificationCodeForAccountRecoveryDTO } from '../models/EnterPhoneVerificationCodeForAccountRecoveryDTO';
import type { PasswordStrengthDTO } from '../models/PasswordStrengthDTO';
import type { StartAccountRecoveryDTO } from '../models/StartAccountRecoveryDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountRecoveryService {

    /**
     * Initiate a retry of the phone verification for an account recovery
     * @param accountRecoveryId
     * @returns void
     * @throws ApiError
     */
    public static retryPhoneVerificationForAccountRecovery(
        accountRecoveryId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/account-recovery/{accountRecoveryId}/retry-phone-verification',
            path: {
                'accountRecoveryId': accountRecoveryId,
            },
            errors: {
                400: `Bad request`,
                404: `Account recovery not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Initiate a restart of the active phone verification, for an account recovery
     * @param accountRecoveryId
     * @returns void
     * @throws ApiError
     */
    public static restartPhoneVerificationForAccountRecovery(
        accountRecoveryId: string,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/account-recovery/{accountRecoveryId}/restart-phone-verification',
            path: {
                'accountRecoveryId': accountRecoveryId,
            },
            errors: {
                400: `Bad request`,
                404: `Account recovery not found`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Enter a phone verification code for an account recovery
     * @param accountRecoveryId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static enterPhoneVerificationCodeForAccountRecovery(
        accountRecoveryId: string,
        requestBody: EnterPhoneVerificationCodeForAccountRecoveryDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/account-recovery/{accountRecoveryId}/phone-verification-code',
            path: {
                'accountRecoveryId': accountRecoveryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Account recovery not found`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Enter a password for an account recovery
     * @param accountRecoveryId
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static enterPasswordForAccountRecovery(
        accountRecoveryId: string,
        requestBody: EnterPasswordForAccountRecoveryDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/rest/account-recovery/{accountRecoveryId}/password',
            path: {
                'accountRecoveryId': accountRecoveryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Account recovery not found`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Evaluate the strength of a provided password for an account recovery
     * @param accountRecoveryId
     * @param requestBody
     * @returns PasswordStrengthDTO Password strength evaluated
     * @throws ApiError
     */
    public static evaluatedPasswordStrengthForAccountRecovery(
        accountRecoveryId: string,
        requestBody: AccountRecoveryPasswordStrengthEvaluationRequestDTO,
    ): CancelablePromise<PasswordStrengthDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rest/account-recovery/{accountRecoveryId}/password-strength',
            path: {
                'accountRecoveryId': accountRecoveryId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                404: `Account recovery not found, or password criteria unknown`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Start an account recovery procedure
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public static startAccountRecovery(
        requestBody: StartAccountRecoveryDTO,
    ): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/rest/account-recovery/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                422: `Invalid input provided`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Retrieve the state of an account recovery process
     * @param accountRecoveryId
     * @returns AccountRecoveryStateDTO Account recovery state retrieved
     * @throws ApiError
     */
    public static accountRecoveryState(
        accountRecoveryId: string,
    ): CancelablePromise<AccountRecoveryStateDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rest/account-recovery/{accountRecoveryId}',
            path: {
                'accountRecoveryId': accountRecoveryId,
            },
            errors: {
                404: `Account recovery state not found`,
                500: `Internal server error`,
            },
        });
    }

}
