/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountEmailChangeStateDTO } from '../models/AccountEmailChangeStateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountEmailChangeService {

    /**
     * Retrieve the state of an attempt to change the email of an account
     * @param attemptId
     * @returns AccountEmailChangeStateDTO Email change state retrieved
     * @throws ApiError
     */
    public static accountEmailChangeState(
        attemptId: string,
    ): CancelablePromise<AccountEmailChangeStateDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/rest/account-email-change/{attemptId}',
            path: {
                'attemptId': attemptId,
            },
            errors: {
                404: `Change attempt not found`,
                500: `Internal server error`,
            },
        });
    }

}
